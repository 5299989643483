import axios from "axios";
import { LOCAL_STORAGE_KEY } from "../constants/common";
import { isKatakana, toKatakana as wanakana } from "wanakana";

const URL_CONVERT_KATAKANA = "https://dc3i1t2n86q86.cloudfront.net/toKatakana";

export const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) || null;
};

export const toConvertKatakana = async (text: string) => {
  try {
    const response = await axios.post(URL_CONVERT_KATAKANA, { message: text }, {});
    const res = response?.data?.res;
    if (!isKatakana(res)) {
      return wanakana(res);
    }
    return res;
  } catch (e) {
    return wanakana(text);
  }
};

export const formatCurrency = (number: number) => {
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
    minimumFractionDigits: 0
  }).format(number);
};

export const convertToCamelCase = (str: string) => {
  return str.replace(/_./g, (match) => match.charAt(1).toUpperCase());
};

export const generateGender = (gender: string) => {
  switch (gender) {
    case "male":
      return "男性";
    case "female":
      return "女性";
  }
};

export const normalizeZipCode = (zipCode: string, lengthAllowed: number) => {
  const zipCodeWhenRemoveCharacter = zipCode.replace(/\D/g, "");
  return zipCodeWhenRemoveCharacter.slice(0, lengthAllowed);
};

export const shortenString = (str: string, length: number) => {
  if (str.length > length) {
    return str.substring(0, length);
  } else {
    return str;
  }
};
