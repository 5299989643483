import { LinearProgress } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ROUTE } from "./constants/paths";
import notAuth from "./hocs/notAuthentication";
import withAuth from "./hocs/withAuthentication";
import { Helmet } from "react-helmet";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false
    }
  }
});

//--- Pages lazy
const LoginPage = notAuth(React.lazy(() => import("./pages/Login")));
const ForgotPasswordPage = notAuth(React.lazy(() => import("./pages/ForgotPassword")));
const SendMailPage = notAuth(React.lazy(() => import("./pages/ForgotPassword/SendMail")));
const MailIsSentPage = notAuth(React.lazy(() => import("./pages/ForgotPassword/SendMailSuccess")));
const NewPasswordPage = notAuth(React.lazy(() => import("./pages/ForgotPassword/EnterNewPassword")));
const UpdatePasswordSuccessPage = notAuth(React.lazy(() => import("./pages/ForgotPassword/UpdatePasswordSuccess")));

const HomePage = withAuth(React.lazy(() => import("./pages/HomePage")));
const CustomerEnterInformationPage = withAuth(React.lazy(() => import("./pages/CustomerEnterInformation")));
const CustomerEnterSuccessPage = withAuth(React.lazy(() => import("./pages/CustomerEnterSuccess")));

const CustomerSurveyPage = withAuth(React.lazy(() => import("./pages/CustomerSurvey")));
const CustomerFormPage = React.lazy(() => import("./pages/CustomerForm"));

const SalonPage = withAuth(React.lazy(() => import("./pages/Salon")));

const RegisterMenuPage = withAuth(React.lazy(() => import("./pages/RegisterMenu")));

const CustomerEchartContainerPage = React.lazy(() => import("./pages/CustomerEchart/container"));
const CustomerEchartPage = withAuth(React.lazy(() => import("./pages/CustomerEchart")));
const TakePhotoPage = withAuth(React.lazy(() => import("./pages/CustomerEchart/TakePhoto")));
const UploadPhotoPage = withAuth(React.lazy(() => import("./pages/CustomerEchart/UploadPhoto")));
const ConfirmPhotoPage = withAuth(React.lazy(() => import("./pages/CustomerEchart/ConfirmPhoto")));
const BasicInfoPage = React.lazy(() => import("./pages/CustomerEchart/BasicInfo"));
const FormInquiryAnswerPage = React.lazy(() => import("./pages/CustomerEchart/FormInquiryAnswer"));

const FaceAnalysisPage = withAuth(React.lazy(() => import("./pages/FaceAnalysis")));

const ProposalPage = withAuth(React.lazy(() => import("./pages/Proposal")));
const SalonProposalPage = withAuth(React.lazy(() => import("./pages/Proposal/Salon")));
const ClinicProposalPage = withAuth(React.lazy(() => import("./pages/Proposal/Clinic")));

const FaceComparisonPage = withAuth(React.lazy(() => import("./pages/FaceComparison")));

function Router() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="" />
      </Helmet>

      <ToastContainer className="custom-react-toastify" />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path={ROUTE.LOGIN} element={<LoginPage />} />

              <Route path={ROUTE.FORGOT_PASSWORD.INDEX} element={<ForgotPasswordPage />}>
                <Route index element={<SendMailPage />} />
                <Route path={ROUTE.FORGOT_PASSWORD.MAIL_IS_SENT} element={<MailIsSentPage />} />
                <Route path={ROUTE.FORGOT_PASSWORD.UPDATE_PASSWORD} element={<NewPasswordPage />} />
                <Route path={ROUTE.FORGOT_PASSWORD.UPDATE_PASSWORD_SUCCESS} element={<UpdatePasswordSuccessPage />} />
              </Route>

              <Route path={ROUTE.HOME} element={<HomePage />} />
              <Route path={ROUTE.CUSTOMER_ENTER_INFORMATION} element={<CustomerEnterInformationPage />} />
              <Route path={ROUTE.CUSTOMER_ENTER_SUCCESS} element={<CustomerEnterSuccessPage />} />

              <Route path={ROUTE.SALON.INDEX} element={<SalonPage />} />

              <Route path={ROUTE.CUSTOMER_SURVEY} element={<CustomerSurveyPage />} />
              <Route path={ROUTE.CUSTOMER_FORM} element={<CustomerFormPage />} />

              <Route path={ROUTE.REGISTER_MENU} element={<RegisterMenuPage />} />

              <Route path={ROUTE.CUSTOMER_ECHART.INDEX + "/:id"} element={<CustomerEchartContainerPage />}>
                <Route index element={<CustomerEchartPage />} />
                <Route path={`${ROUTE.CUSTOMER_ECHART.TAKE_PHOTO}/:customerEchartId`} element={<TakePhotoPage />} />
                <Route path={`${ROUTE.CUSTOMER_ECHART.UPLOAD_PHOTO}/:customerEchartId`} element={<UploadPhotoPage />} />
                <Route
                  path={`${ROUTE.CUSTOMER_ECHART.CONFIRM_PHOTO}/:customerEchartId`}
                  element={<ConfirmPhotoPage />}
                />
              </Route>

              <Route path={ROUTE.CUSTOMER_BASIC_INFO + "/:id"} element={<BasicInfoPage />} />
              <Route
                path={ROUTE.CUSTOMER_ECHART.INDEX + "/:customerEchartId/inquiry-answer/:companyId"}
                element={<FormInquiryAnswerPage />}
              />

              <Route path={`${ROUTE.FACE_ANALYSIS}/:id`} element={<FaceAnalysisPage />} />

              <Route path={ROUTE.PROPOSAL.INDEX} element={<ProposalPage />}>
                <Route index element={<SalonProposalPage />} />
                <Route path={`${ROUTE.PROPOSAL.INDEX}/${ROUTE.PROPOSAL.CLINIC}`} element={<ClinicProposalPage />} />
              </Route>

              <Route path={`${ROUTE.FACE_COMPARISON.INDEX}/:id`} element={<FaceComparisonPage />} />

              <Route path="*" element={<div>404</div>} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
