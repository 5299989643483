export const ROUTE = {
  HOME: "/",
  LOGIN: "/login",

  FORGOT_PASSWORD: {
    INDEX: "/forgot-password",
    SEND_MAIL: "send-mail",
    MAIL_IS_SENT: "mail-is-sent",
    UPDATE_PASSWORD: "update",
    UPDATE_PASSWORD_SUCCESS: "update-success"
  },

  SALON: {
    INDEX: "/salon",
    CURRENT_PLAN: "#current-plan"
  },

  CUSTOMER_SURVEY: "/customer-survey",
  CUSTOMER_FORM: "/customer-form",
  CUSTOMER_BASIC_INFO: "/customer-basic-info",

  CUSTOMER_ECHART: {
    INDEX: "/customer-echart",
    INFO: "#info",
    TAKE_PHOTO: "take-photo",
    UPLOAD_PHOTO: "upload-photo",
    CONFIRM_PHOTO: "confirm-photo"
  },

  CUSTOMER_ENTER_INFORMATION: "/customer-enter-information",
  CUSTOMER_ENTER_SUCCESS: "/customer-enter-success",

  REGISTER_MENU: "/register-menu",

  FACE_ANALYSIS: "/face-analysis",
  PROPOSAL: {
    INDEX: "/proposal",
    SALON: "salon",
    CLINIC: "clinic"
  },
  FACE_COMPARISON: {
    INDEX: "/face-comparison"
  },
  NOT_FOUND: "/404"
};
